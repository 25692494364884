import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

export interface OptionInfo {
  value: string;
  image: string;
}

@Component({
  templateUrl: './select.component.html',
  styleUrls: [ './select.component.scss' ]
})
export class SelectComponent implements OnInit {
  @Input() options: string[] = [ ];
  @Output() home: EventEmitter<boolean> = new EventEmitter();
  @Output() selected: EventEmitter<string> = new EventEmitter();

  headerImageSrc = '';

  optionInfo: OptionInfo[] = [ ];
  selectedOptions: OptionInfo[] = [];

  ngOnInit() {
    this.selectedOptions = this.options.map(option => {
      const found = this.optionInfo.find(info => info.value === option);
      if(found) return found;
    }).filter(Boolean);
  }
}
