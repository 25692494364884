import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PickComponent } from 'src/app/pick/pick.component';
import { WatchComponent } from 'src/app/watch/watch.component';
import { DecadesComponent } from 'src/app/select/decades.component';
import { ConfigComponent } from './config/config.component';
import { HomeComponent } from './home/home.component';
import { PartyGuard } from './shared/guards/party.guard';


const routes: Routes = [
  {
    path: 'config',
    component: ConfigComponent
  },
  {
    path: 'pick',
    component: PickComponent,
    canActivate: [ PartyGuard ]
  },
  {
    path: 'watch',
    component: WatchComponent,
    canActivate: [ PartyGuard ]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [ PartyGuard ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    PartyGuard
  ],
  declarations: [
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
