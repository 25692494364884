<div class="forms">
  <label for="pass">Enter your admin password</label>
  <input type="password" id="pass" [formControl]="password" appKeyboardControl autocomplete="off">

  <p>Select a party to start</p>
  <select name="party" id="party" [formControl]="partySelect">
    <option *ngFor="let party of parties" [value]="party.id">{{ party.date }} - {{ party.name }}</option>
  </select>
  <div class="buttons">
    <button (click)="onStartParty()">Start</button>
    <button (click)="onStartParty('/watch')">Watch</button>
    <button (click)="onDeleteParty()">Delete</button>
  </div>

  <p>Or create a party</p>
  <form [formGroup]="createParty">
    <label for="name">Party name</label>
    <input type="text" id="name" formControlName="name" appKeyboardName autocomplete="off">

    <label for="styles">Party styles</label>
    <select name="styles" id="styles" formControlName="styles" multiple>
      <option *ngFor="let style of styles" [value]="style">{{ style }}</option>
    </select>

    <label for="decades">Party decades</label>
    <select name="decades" id="decades" formControlName="decades" multiple>
      <option *ngFor="let decade of decades" [value]="decade.value">{{ decade.display }}</option>
    </select>
  </form>
  <div class="buttons">
    <button (click)="onCreateParty()">Create</button>
  </div>
</div>
