import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Filter, Song } from '../shared/services/http.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as lunr from 'lunr';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnChanges {
  @Input() list: Song[];
  @Output() filteredListEvent = new EventEmitter<{ query: string, songs: Song[] }>();
  search: FormControl<string>;
  faSearch = faSearch;

  filter: Filter;

  private idx: lunr.Index;
  private songById: Record<string, Song> = { };

  constructor(private fb: FormBuilder) {
    this.search = this.fb.control('');
  }

  ngOnInit(): void {
    const filter = JSON.parse(localStorage.getItem('filter')) as Filter;
    this.filter = filter;

    this.search.valueChanges.subscribe(() => {
      this.filterList(this.search.value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.list &&
      !changes.list.previousValue?.length &&
      changes.list.currentValue.length) {
      this.idx = lunr(function () {
        this.field('title');
        this.field('artist');
        for (const song of changes.list.currentValue) {
          this.add(song);
        }
      });
      for (const song of this.list) {
        this.songById[song.id] = song;
      }
    }
  }

  filterList(query: string) {
    const terms = query.split(' ');
    const fields = this.getFields();
    const results = this.idx.query(q => {
      terms.forEach(term => q.term(term, { fields }));
    });
    const refs = results.map(result => result.ref);
    const indexFiltered = refs.map(ref => this.songById[ref]);
    const containsFiltered = this.list.filter(song => {
      if (refs.includes(song.id)) return false;
      return fields.some(field => song[field].includes(query));
    });
    const songs = indexFiltered.concat(...containsFiltered);
    this.filteredListEvent.emit({ query, songs });
  }

  private getFields() {
    switch (this.filter?.sortBy) {
      case 'artist':
      case 'title':
        return [ this.filter.sortBy ];
      default:
        return [ 'title', 'artist' ];
    }
  }

}
