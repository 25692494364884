import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { filter, map, tap } from "rxjs/operators";
import { Song } from "./http.service";
import { isEqual as _isEqual } from 'lodash';
import * as F from '@angular/fire';

export type SongRequest = Song & { partyId: string, requestedAt: number, played: boolean };
export type GroupedRequest = SongRequest & { count: number };

@Injectable()
export class RequestService {
  partyId: string;
  requests: AngularFirestoreCollection<SongRequest>;

  last: SongRequest[] = undefined;

  constructor(private firestore: AngularFirestore) {
    this.partyId = JSON.parse(localStorage.getItem('party')).id;
    this.requests = this.firestore.collection<SongRequest>('requests', ref => ref
      .where('partyId', '==', this.partyId)
      .orderBy('requestedAt', 'asc')
    );
  }

  get update$() {
    return this.requests.valueChanges().pipe(
      filter(values => !_isEqual(this.last, values)),
      tap(values => this.last = values),
      map(values => {
        const grouped: GroupedRequest[] = [ ];
        values.forEach(value => {
          const found = grouped.find(el => el.id === value.id);
          if (found) {
            found.count++;
            found.played = found.played || value.played;
          }
          else grouped.push({ ...value, count: 1 });
        });
        return grouped;
      })
    );
  }

  add(song: Song) {
    if (!song.id) song.id = song.title;
    return this.requests.add({ ...song, partyId: this.partyId, requestedAt: Date.now(), played: false });
  }

  async markPlayed(id: string) {
    const results = await this.requests.ref.where('id', '==', id).get();
    if (results.empty) return;
    const doc = results.docs.find(doc => doc.data().partyId === this.partyId);
    if (!doc) return;
    return doc.ref.set({ played: true }, { merge: true });
  }
}
