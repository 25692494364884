import { Component, OnInit } from '@angular/core';
import { RequestedSong } from '../shared/services/http.service';
import { GroupedRequest, RequestService } from '../shared/services/requests.service';

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss']
})
export class WatchComponent implements OnInit {
  requested: GroupedRequest[] = [];

  constructor(
    private requestService: RequestService
  ) { }

  ngOnInit() {
    this.requestService.update$.subscribe(values => this.requested = values);
  }

  async onItemClicked(item: RequestedSong) {
    await this.requestService.markPlayed(item.id);
  }

}
