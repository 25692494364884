import { Directive, ElementRef, HostListener } from "@angular/core";
import { FormControlDirective, FormControlName } from "@angular/forms";
import { KeyboardService } from "../keyboard.service";

@Directive()
export abstract class KeyboardDirective {

  constructor(
    private el: ElementRef,
    private keyboardService: KeyboardService,
    private form: FormControlName | FormControlDirective
  ) {
    this.el.nativeElement.readOnly = true;
  }

  @HostListener('focus') onFocus() {
    this.keyboardService.setListener(this.form.control);
    setTimeout(() => {
      this.el.nativeElement.readOnly = false;
    }, 100);
  }

  @HostListener('blur') onBlur() {
    this.el.nativeElement.readOnly = true;
  }

}
