import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { first, map } from 'rxjs/operators';

export interface PartyOptions {
  name: string;
  styles: string[];
  decades: string[];
}

export interface Song {
  id: string;
  title: string;
  artist: string;
  style: string;
  decade: string;
}

export type Filter = Partial<Song> & { sortBy?: keyof Song; };

export type RequestedSong = Pick<Song, 'id' | 'artist' | 'title'> & { count: number, played: boolean };

export type Party = PartyOptions & {
  id: string;
  created_at: dayjs.Dayjs;
};

@Injectable()
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }

  auth(password: string) {
    const headers = this.getHeaders(password);
    return this.http.get<{ success: boolean }>('/api/auth', { headers }).pipe(first());
  }

  getSongs(filter?: Filter, party?: Party) {
    const url = new URL('/api/songs', window.origin);
    if (filter) {
      for (const key in filter) {
        url.searchParams.set(key, filter[key]);
      }
    }
    if (party) {
      if (!url.searchParams.has('decade')) url.searchParams.set('decade', party.decades.join(','))
      if (!url.searchParams.has('style')) url.searchParams.set('style', party.styles.join(','))
    }
    return this.http.get<Song[]>(url.href).pipe(first());
  }

  getParties() {
    return this.http.get<Party[]>('/api/parties').pipe(
      map(parties => parties.map(this.mapParty)),
      first()
    );
  }

  getParty(id: string) {
    return this.http.get<Party>(`/api/party/${id}`).pipe(
      map(this.mapParty),
      first()
    );
  }

  createParty(options: PartyOptions, password: string) {
    const headers = this.getHeaders(password);
    return this.http.post<Party>('/api/party', options, { headers }).pipe(
      map(this.mapParty),
      first()
    );
  }

  deleteParty(id: string, password: string) {
    const headers = this.getHeaders(password);
    return this.http.delete<void>(`/api/party/${id}`, { headers }).pipe(first());
  }

  private mapParty(party: Party) {
    party.created_at = dayjs(party.created_at);
    return party;
  }

  private getHeaders(password: string): HttpHeaders {
    const basic = `admin:${password}`;
    return new HttpHeaders({
      authorization: `Basic ${window.btoa(basic)}`
    });
  }

}
