import { Directive } from "@angular/core";
import { NavButtonComponent } from "./nav-button.component";

@Directive({
  selector: '[app-nav-back]'
})
export class BackButtonDirective {
  background = 'https://storage.googleapis.com/vjrnb-assets/tela03a/botao_back.svg';

  constructor(private host: NavButtonComponent) {
    this.host.background = this.background;
    this.host.classes = [ 'left' ];
  }
}
