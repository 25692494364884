<div *ngIf="!selectDecade && !selectStyle">
  <div class="col-1">
    <img src="https://storage.googleapis.com/vjrnb-assets/tela01/botao_artist.svg" (click)="onButtonClicked('artist')">
  </div>
  <div class="col-2">
    <img src="https://storage.googleapis.com/vjrnb-assets/tela01/botao_timeline.svg" (click)="onButtonClicked('decade')">
  </div>
  <div class="col-1">
    <img src="https://storage.googleapis.com/vjrnb-assets/tela01/botao_style.svg" (click)="onButtonClicked('style')">
  </div>
  <div class="col-2">
    <img src="https://storage.googleapis.com/vjrnb-assets/tela01/botao_song.svg" (click)="onButtonClicked('title')">
  </div>
</div>
<app-decades
  *ngIf="selectDecade"
  [options]="party.decades"
  (home)="selectDecade = false"
  (selected)="onDecadeSelected($event)"
></app-decades>
<app-styles
  *ngIf="selectStyle"
  [options]="party.styles"
  (home)="selectStyle = false"
  (selected)="onStyleSelected($event)"
></app-styles>
