import { Component } from '@angular/core';
import { OptionInfo, SelectComponent } from './select.component';

@Component({
  selector: 'app-decades',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class DecadesComponent extends SelectComponent {

  headerImageSrc = 'https://storage.googleapis.com/vjrnb-assets/tela01/botao_timeline.svg';

  optionInfo: OptionInfo[] = [
    { value: '60', image: 'https://storage.googleapis.com/vjrnb-assets/tela02b/botao_60.svg' },
    { value: '70', image: 'https://storage.googleapis.com/vjrnb-assets/tela02b/botao_70.svg' },
    { value: '80', image: 'https://storage.googleapis.com/vjrnb-assets/tela02b/botao_80.svg' },
    { value: '90', image: 'https://storage.googleapis.com/vjrnb-assets/tela02b/botao_90.svg' }
  ];

}
