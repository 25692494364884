import { Component } from '@angular/core';
import { OptionInfo, SelectComponent } from './select.component';

@Component({
  selector: 'app-styles',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class StylesComponent extends SelectComponent {

  headerImageSrc = 'https://storage.googleapis.com/vjrnb-assets/tela01/botao_style.svg';

  optionInfo: OptionInfo[] = [
    { value: 'ballad', image: 'https://storage.googleapis.com/vjrnb-assets/tela02c/botao_ballads.svg' },
    { value: 'ballads', image: 'https://storage.googleapis.com/vjrnb-assets/tela02c/botao_ballads.svg' },
    { value: 'dance', image: 'https://storage.googleapis.com/vjrnb-assets/tela02c/botao_dance.svg' },
    { value: 'classic rock', image: 'https://storage.googleapis.com/vjrnb-assets/tela02c/botao_classic_rock.svg' }
  ];

}
