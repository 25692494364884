import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WatchComponent } from 'src/app/watch/watch.component';
import { PickComponent } from 'src/app/pick/pick.component';
import { HomeComponent } from './home/home.component';
import { HttpService } from './shared/services/http.service';
import { HttpClientModule } from '@angular/common/http';
import { ConfigComponent } from './config/config.component';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyboardModule } from './keyboard/keyboard.module';
import { KeyboardComponent } from './keyboard/keyboard.component';
import { SongListComponent } from './song-list/song-list.component';
import { DecadesComponent } from './select/decades.component';
import { NavButtonComponent } from './nav-button/nav-button.component';
import { StylesComponent } from './select/styles.component';
import { SelectComponent } from './select/select.component';
import { HomeButtonDirective } from './nav-button/home-button.directive';
import { BackButtonDirective } from './nav-button/back-button.directive';
import { environment } from 'src/environments/environment';
import { RequestService } from './shared/services/requests.service';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PickComponent,
    WatchComponent,
    ConfigComponent,
    SongListComponent,
    DecadesComponent,
    StylesComponent,
    NavButtonComponent,
    SelectComponent,
    HomeButtonDirective,
    BackButtonDirective,
    SearchBarComponent,
    ConfirmModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    KeyboardModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    FontAwesomeModule
  ],
  providers: [
    HttpService,
    RequestService
  ],
  bootstrap: [AppComponent, KeyboardComponent]
})
export class AppModule { }
