import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpService, Party, PartyOptions } from '../shared/services/http.service';
import { orderBy as _orderBy } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  partySelect: FormControl<string>;
  createParty: FormGroup<{
    name: FormControl<string>,
    styles: FormControl<string[]>,
    decades: FormControl<string[]>
  }>;
  password: FormControl<string>;

  parties: (Party & { date: string })[];
  styles: string[] = [ ];
  decades: { value: string, display: string }[] = [ ];

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private router: Router
  ) {
    this.partySelect = this.fb.control(undefined);
    this.createParty = this.fb.group({
      name: '',
      styles: [],
      decades: []
    });
    this.password = this.fb.control('');
  }

  ngOnInit(): void {
    const party = JSON.parse(localStorage.getItem('party'));
    if (party) this.partySelect.setValue(party.id);
    this.httpService.getParties().subscribe(parties => {
      this.parties = parties.map(party => {
        const date = party.created_at.format('DD/MM/YYYY');
        return { ...party, date };
      });
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari && !party && this.parties.length) this.partySelect.setValue(this.parties[0].id);
    });
    this.httpService.getSongs().subscribe(songs => {
      const _styles = [ ];
      const _decades = [ ];
      songs.forEach(song => {
        if (!_styles.includes(song.style)) _styles.push(song.style);
        if (song.decade && !_decades.find(d => d.value === song.decade)) _decades.push({
          value: song.decade,
          display: this.displayDecade(song.decade)
        });
      });
      this.styles = _styles.sort();
      this.decades = _orderBy(_decades, 'display');
    });
  }

  getPassword() {
    const pass = this.password.value;
    this.password.reset();
    return pass;
  }

  displayDecade(decade: string) {
    if (!decade) return 'BLANK';
    if (decade.length === 4) return decade;
    return +decade < 30 ? `20${decade}` : `19${decade}`;
  }

  onDeleteParty() {
    const partyId = this.partySelect.value;
    const party = this.parties.find(p => p.id === partyId);
    if (!party) return;
    const willDelete = confirm(`Are you sure you want to delete the party:\n${party.name}`);
    if (!willDelete) return;
    const pass = this.getPassword();
    this.httpService.deleteParty(partyId, pass).subscribe({
      error: err => {
        console.error(err);
        alert(err?.message || err);
      }
    });
  }

  onStartParty(redirect = '/') {
    const partyId = this.partySelect.value;
    const party = this.parties.find(p => p.id === partyId);
    if (!party) return;
    const pass = this.getPassword();
    this.httpService.auth(pass).subscribe({
      next: _ => {
        this.startParty(party, redirect);
      },
      error: err => {
        console.error(err);
        alert(err?.message || err);
      }
    });
  }

  onCreateParty() {
    const options = this.createParty.value as PartyOptions;
    const pass = this.getPassword();
    this.httpService.createParty(options, pass).subscribe({
      next: party => {
        this.startParty(party);
      },
      error: err => {
        console.error(err);
        alert(err?.message || err);
      }
    });
  }

  private startParty(party: Party, redirect = '/') {
    localStorage.setItem('party', JSON.stringify(party));
    this.router.navigate([ redirect ]);
  }

}
