import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() show: boolean;
  @Input() message: string;
  @Output() response: EventEmitter<boolean> = new EventEmitter();

}
