import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService, Filter, Song, Party } from '../shared/services/http.service';
import { RequestService } from '../shared/services/requests.service';

@Component({
  selector: 'app-pick',
  templateUrl: './pick.component.html',
  styleUrls: ['./pick.component.scss']
})
export class PickComponent implements OnInit {
  searchQuery: string;
  filteredList: Song[];
  list: Song[] = [];
  filterTitle: string;
  filter: Filter;

  selected: Song;
  confirmModalMessage: string;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private requestService: RequestService
  ) { }

  async ngOnInit() {
    const filter = JSON.parse(localStorage.getItem('filter')) as Filter;
    this.filter = filter;
    const party = JSON.parse(localStorage.getItem('party') || 'null') as Party;
    this.httpService.getSongs(filter, party).subscribe(songs => {
      this.list = songs;
      this.filteredList = this.list;
    });
  }

  async onItemClicked(song: Song) {
    this.selected = song;
    const songDisplay = `${song.artist ? `${song.artist} - ` : ''}${song.title}`;
    this.confirmModalMessage = `Confirma sua escolha?\n${songDisplay}`;
  }

  onBackClicked() {
    this.router.navigate([ '/' ], { state: {
      filter: this.filter
    } });
  }

  updateFilteredList(result: { query: string, songs: Song[] }) {
    this.searchQuery = result.query;
    this.filteredList = result.songs;
  }

  async onConfirmResponse(response: boolean) {
    if (response) await this.requestService.add(this.selected);
    this.selected = undefined;
    this.confirmModalMessage = undefined;
  }

}
