import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
export class PartyGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate() {
    const localParty = localStorage.getItem('party');
    if (!localParty) return this.deny();
    try {
      const party = JSON.parse(localParty);
      if (party) return true;
      return this.deny();
    } catch (error) {
      return this.deny();
    }
  }

  private deny() {
    return this.router.parseUrl('/config');
  }
}
