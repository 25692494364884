export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBZWFjWesf5NWicDwfjJezxb_z1hgyKu80",
    authDomain: "vjrnb-356200.firebaseapp.com",
    projectId: "vjrnb-356200",
    storageBucket: "vjrnb-356200.appspot.com",
    messagingSenderId: "363972944978",
    appId: "1:363972944978:web:adee1b51df865c61756b8b"
  }
};
