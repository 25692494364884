import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Party, Filter } from '../shared/services/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  party: Party;

  selectDecade = false;
  selectStyle = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.party = JSON.parse(localStorage.getItem('party')) as Party;
    const filter = history.state.filter as Filter;
    if (filter?.decade) this.selectDecade = true;
    else if (filter?.style) this.selectStyle = true;
  }

  onButtonClicked(filter: string) {
    switch (filter) {
      case 'title':
      case 'artist':
        const filterObject = { sortBy: filter };
        this.pick(filterObject);
      break;
      case 'style':
        this.selectStyle = true;
      break;
      case 'decade':
        this.selectDecade = true;
      break;
    }
  }

  onStyleSelected(style: string) {
    this.selectStyle = false;
    const filterObject = { style };
    this.pick(filterObject);
  }

  onDecadeSelected(decade: string) {
    this.selectDecade = false;
    const filterObject = { decade };
    this.pick(filterObject);
  }

  pick(filter: Filter) {
    localStorage.setItem('filter', JSON.stringify(filter));
    this.router.navigate(['/pick']);
  }

}
