import { Directive, ElementRef } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { KeyboardService } from '../keyboard.service';
import { KeyboardDirective } from './keyboard.directive';

@Directive({
  selector: '[appKeyboardControl]'
})
export class KeyboardControlDirective extends KeyboardDirective {

  constructor(
    el: ElementRef,
    keyboardService: KeyboardService,
    formControlDirective: FormControlDirective
  ) {
    super(el, keyboardService, formControlDirective);
  }
}
