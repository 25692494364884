import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: [ './nav-button.component.scss' ]
})
export class NavButtonComponent {
  @Input() route: string;
  @Output() click: EventEmitter<boolean> = new EventEmitter();

  background: string;
  classes: string[] = [ ];

  constructor(private router: Router) { }

  async onClick() {
    const result = this.route ? await this.router.navigate([ this.route ]) : true;
    this.click.emit(result);
  }

  get backgroundImage() {
    return `url('${this.background}')`;
  }

  get elClass() {
    return this.classes.join(' ');
  }

}
