import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, OnInit } from '@angular/core';
import { Filter, Song } from '../shared/services/http.service';
import { GroupedRequest } from '../shared/services/requests.service';

type SongLike = Song | GroupedRequest;

@Component({
  selector: 'app-song-list',
  templateUrl: './song-list.component.html',
  styleUrls: ['./song-list.component.scss']
})
export class SongListComponent implements OnInit, OnChanges {
  @Input() searchQuery: string;
  @Input() array: SongLike[];
  @Output() itemClicked = new EventEmitter<SongLike>();

  filter: Filter;

  suggestSongText = "Não encontrou o que procurava? Clique aqui para sugerir!";

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    const filter = JSON.parse(localStorage.getItem('filter')) as Filter;
    this.filter = filter;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.array) {
      // console.log(changes.array.currentValue);
      this.cd.detectChanges();
    }
  }

  onItemClicked(item: SongLike) {
    this.itemClicked.emit(item);
  }

  firstColumn(item: SongLike) {
    return this.filter?.sortBy === 'title' ? item.title : item.artist;
  }

  secondColumn(item: SongLike) {
    return this.filter?.sortBy === 'title' ? item.artist : item.title;
  }

  hasBothColumns(item: SongLike) {
    return this.firstColumn(item) && this.secondColumn(item);
  }

  sendSuggestion() {
    this.itemClicked.emit({ title: this.searchQuery } as any);
  }
}
