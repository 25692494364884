import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";

@Injectable()
export class KeyboardService {

  private setListenerSubject: Subject<FormControl<string>>;

  constructor() {
    this.setListenerSubject = new Subject();
  }

  setListener(listener: FormControl<string>) {
    this.setListenerSubject.next(listener);
  }

  get listener$() {
    return this.setListenerSubject.asObservable();
  }

}
