import { AfterViewInit, Component } from "@angular/core";
import { KeyboardService } from "./keyboard.service";
import { KeyboardOptions } from "simple-keyboard/build/interfaces";
import { FormControl } from "@angular/forms";
import Keyboard from 'simple-keyboard';
import { Router } from "@angular/router";

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements AfterViewInit {

  keyboard: Keyboard;
  visible = false;

  listener: FormControl<string>;

  constructor(
    private keyboardService: KeyboardService,
    private router: Router
  ) {
    this.keyboardService.listener$.subscribe((listener) => {
      if (!listener) return;
      this.visible = true;
      this.listener = listener;
      this.keyboard.setInput(this.listener.value);
    });

    this.router.events.subscribe(() => this.visible = false);
  }

  ngAfterViewInit() {
    this.keyboard = new Keyboard(this.keyboardOptions);
  }

  onKeyPress(button: string) {
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    else if (button === "{numbers}" || button === "{abc}") this.handleNumbers();
    else if (button === '{ent}') this.handleEnter();
  }

  onChange(value: string) {
    if (!this.listener) return;
    this.listener.setValue(value);
  }

  private handleShift() {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  }

  private handleNumbers() {
    let currentLayout = this.keyboard.options.layoutName;
    let numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";

    this.keyboard.setOptions({
      layoutName: numbersToggle
    });
  }

  private handleEnter() {
    this.listener = undefined;
    this.visible = false;
    this.keyboard.setInput('');
  }

  private get keyboardOptions() {
    return {
      onChange: input => this.onChange(input),
      onKeyPress: (button: string) => this.onKeyPress(button),
      mergeDisplay: true,
      layoutName: "default",
      layout: {
        default: [
          "1 2 3 4 5 6 7 8 9 0",
          "q w e r t y u i o p",
          "a s d f g h j k l",
          "z x c v b n m {backspace}",
          "{numbers} {space} {ent}"
        ],
        shift: [
          "1 2 3 4 5 6 7 8 9 0",
          "Q W E R T Y U I O P",
          "A S D F G H J K L",
          "{shift} Z X C V B N M {backspace}",
          "{numbers} {space} {ent}"
        ],
        numbers: [
          "- 1 2 3",
          ", 4 5 6",
          ". 7 8 9",
          "' & 0 #",
          "{abc} {space} {backspace}"]
      },
      display: {
        "{numbers}": "123",
        "{ent}": "\u25B3",
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "⌫",
        "{capslock}": "caps lock ⇪",
        "{shift}": "⇧",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC",
        "{space}": "\u2423"
      }
    } as KeyboardOptions;
  }

}
