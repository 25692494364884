<div *ngFor="let item of array" class="song-item" [class.highlighted]="item.played" (click)="onItemClicked(item)">
    <div class="sel">
        <div *ngIf="firstColumn(item)" [class.half]="hasBothColumns(item)">
            <pre>{{ firstColumn(item) | lowercase }}</pre>
        </div>
        <div *ngIf="secondColumn(item)" [class.half]="hasBothColumns(item)">
            <pre>{{ secondColumn(item) | lowercase }}</pre>
        </div>
    </div>
    <div *ngIf="item.count > 1" class="badge">
        <span>{{ item.count }}</span>
    </div>
</div>
<div *ngIf="searchQuery" class="song-item" (click)="sendSuggestion()">
    <div class="sel centered">
        <div>
            <pre class="big-text">{{ suggestSongText | uppercase }}</pre>
        </div>
    </div>
</div>
