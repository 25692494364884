<div class="grid">
  <app-search-bar [list]="list" (filteredListEvent)="updateFilteredList($event)"></app-search-bar>
  <app-song-list [array]="filteredList" [searchQuery]="searchQuery" (itemClicked)="onItemClicked($event)"></app-song-list>
</div>
<app-nav-button (click)="onBackClicked()" app-nav-back></app-nav-button>
<app-nav-button route="/" app-nav-home></app-nav-button>
<app-confirm-modal
[show]="!!selected"
[message]="confirmModalMessage"
(response)="onConfirmResponse($event)"
></app-confirm-modal>
