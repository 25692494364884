import { NgModule } from "@angular/core";
import { KeyboardComponent } from "./keyboard.component";
import { KeyboardService } from "./keyboard.service";
import { KeyboardNameDirective } from './directives/keyboard-name.directive';
import { KeyboardControlDirective } from "./directives/keyboard-control.directive";

@NgModule({
  declarations: [
    KeyboardComponent,
    KeyboardControlDirective,
    KeyboardNameDirective
  ],
  providers: [ KeyboardService ],
  exports: [
    KeyboardComponent,
    KeyboardControlDirective,
    KeyboardNameDirective
  ]
})
export class KeyboardModule { }
